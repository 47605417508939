<template>
  <!-- 头像 -->
  <div align="center" class="pt-2">
    <el-avatar @click="tkinfo" :size="50" :src="avatar"></el-avatar>
  </div>
  <el-divider></el-divider>
  <!-- class="el-menu-vertical-demo" -->
  <el-menu
    background-color="#232527"
    :default-active="path"
    @select="handleSelect"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-menu-item index="/">
      <i class="el-icon-s-home"></i>
      <template #title> home </template>
    </el-menu-item>

    <el-menu-item index="/chart">
      <i class="el-icon-pie-chart"></i>
      <template #title> 仪表盘 </template>
    </el-menu-item>

    <el-menu-item index="docs">
      <i class="el-icon-place"></i>
      <template #title> 文档 </template>
    </el-menu-item>
    <el-menu-item index="/play">
      <i class="el-icon-sugar"></i>
      <template #title> 游戏 </template>
    </el-menu-item>
    <el-menu-item index="/strive">
      <i class="el-icon-collection-tag"></i>
      <template #title> Strive </template>
    </el-menu-item>
    <el-menu-item index="/video">
      <i class="el-icon-video-camera-solid"></i>
      <template #title> video </template>
    </el-menu-item>
    <el-menu-item index="/extension">
      <i class="el-icon-guide"></i>
      <template #title> window </template>
    </el-menu-item>
    <el-menu-item index="/cool">
      <i class="el-icon-lollipop"></i>
      <template #title> cool </template>
    </el-menu-item>
    <el-menu-item index="/my">
      <i class="el-icon-moon-night"></i>
      <template #title> 我的 </template>
    </el-menu-item>
  </el-menu>
  <!-- 自我介绍 -->
  <business-card ref="businessEle"></business-card>
</template>

<script>
import avatar from "@/assets/me.jpg";
import { ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import businessCard from "@/components/business-card";
export default {
  name: "user-aside",
  components: { businessCard },
  setup() {
    let path = ref("/");
    let businessEle = ref();
    const route = new useRoute();
    const router = new useRouter();
    // 自我介绍
    const tkinfo = ()=>{
      businessEle.value.show()
    }
    // 挂载前
    onBeforeMount(() => {
      // 初始化 菜单状态
      path.value = route.path;
    });

    const handleOpen = (to) => {
      console.log(to, "open");
    };
    const handleClose = (to) => {
      console.log(to, "close");
    };
    const handleSelect = (to) => {
      if (to == "docs") {
        // 文档
        return window.open("http://docs.yunfei.ltd", "blank");
      }
      router.push(to);
    };
    return { avatar, path, handleOpen, handleClose, handleSelect, businessEle,tkinfo };
  },
};
</script>

<style>
/* 字体颜色 */
.el-menu .el-menu-item {
  color: #dee1e6;
}
/* 覆盖默认样式 */
.el-dropdown-menu,
.el-menu {
  border-right: none !important;
  /* background: none !important; */
}
.el-menu-item:focus,
.el-menu-item:hover {
  /* background: none !important; */
}

/* 分割线 */
.el-divider--horizontal {
  margin: 15px 0 !important;
}
</style>