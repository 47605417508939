<template>
  <el-main :class="className">
    <router-view></router-view>
  </el-main>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "mains",
  data() {
    return {
      className: "yf_main",
    };
  },
  computed: {
    ...mapState({
      shows: (state) => state.asides.shows,
    }),
  },
  watch: {
    $route: {
      handler: function ({ path }) {
        this.className = "";
        // 全屏显示
        if (path == "/chart" || path == "/cool") {
          this.className = "yf_main-pd";
          this.changShow(false);
        } else {
          const width = document.body.clientWidth;
          // 非cool页面下，自动切换
          if (width <= 700 && this.shows) {
            this.$nextTick(() => {
              this.changShow(false);
            });
          } else if (width > 700 && !this.shows) {
            this.$nextTick(() => {
              this.changShow(true);
            });
          }
        }
        // chart下背景色
        if (path === "/chart") {
          this.className = `${this.className} yf_main-chart`;
        } else {
          this.className = `${this.className} yf_main`;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["changShow"]),
  },
};
</script>

<style>
.yf_main {
  background-color: #ebecf1;
}
/* chart */
#app .yf_main-pd {
  padding: 0;
  overflow-x: hidden;
}
#app .yf_main-chart {
  background-color: #000d4a;
}
</style>