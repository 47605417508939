<template>
  <el-container class="yf_home">
    <asides />
    <mains />
  </el-container>
</template>

<script>
import { onMounted } from "vue";
import Asides from "@/components/layout/asides";
import Mains from "@/components/layout/mains";
export default {
  name: "home",
  components: { Asides, Mains },
  setup() {
    onMounted(() => {});
  },
};
</script>

<style>
.yf_home {
  width: 100vw;
  height: 100vh;
}
</style>