<template>
  <el-affix :offset="10">
    <el-button
      size="mini"
      type="primary"
      style="padding: 0"
      @click="handleToggle"
      icon="el-icon-caret-right"
    >
    </el-button>
  </el-affix>
  <!-- 面板 -->
  <el-drawer
    custom-class="aside_drawer"
    size="150px"
    v-model="show"
    direction="ltr"
    :with-header="false"
  >
    <user-aside />
  </el-drawer>
</template>

<script>
import { ref, onMounted } from "vue";
import userAside from "./user-aside.vue";
export default {
  name: "user-drawer",
  components: { userAside },
  setup() {
    let show = ref(false);
    const handleToggle = () => {
      show.value = !show.value;
    };
    onMounted(() => {});
    return { show, handleToggle };
  },
};
</script>

<style>
.yf_aside .el-drawer {
  background-color: #232527;
}
.yf_aside .aside_drawer {
  overflow: auto;
}
</style>