<template>
  <el-aside class="yf_aside" :width="shows ? `150px` : '0px'">
    <div v-if="shows">
      <user-aside />
    </div>
    <div v-if="!shows">
      <aside-drawer />
    </div>
  </el-aside>
</template>

<script>
import { onMounted, onUnmounted, computed ,nextTick} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// 内容
import userAside from "./user-aside.vue";
import asideDrawer from "./aside-drawer";
export default {
  name: "asides",
  components: { userAside, asideDrawer },
  setup() {
    const { path } = useRoute();
    const Store = useStore();
    let shows = computed(() => Store.state.asides.show);

    resizeCallback();
    onMounted(() => {
      window.addEventListener("resize", resizeCallback);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeCallback);
    });
    // 监听窗口大小
    function resizeCallback() {
      const width = document.body.clientWidth;
      if (path == "/chart" || path == "/cool") {
        return;
      }
      // 非cool页面下，自动切换
      if (width <= 700 && shows.value) {
        nextTick(()=>{
          Store.commit("changShow", false);
        })
      } else if (width > 700 && !shows.value) {
         nextTick(()=>{
           Store.commit("changShow", true);
        })
      }
    }

    return { shows };
  },
};
</script>

<style>
.yf_aside {
  background-color: #232527;
}
</style>