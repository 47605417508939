<template>
  <el-dialog
    title="自我介绍"
    :append-to-body="true"
    width="340px"
    v-model="dialogFormVisible"
  >
    <el-card className="yf_card_box">
      <template v-slot:header>
        <el-row>
          <el-col :span="10">
            <el-image
              style="width: 100px; height: auto"
              :src="avatar"
            ></el-image>
          </el-col>
          <el-col :span="14">
            <el-row class="zwjs_box">
              <el-col :span="8"
                ><div class="grid-content bg-purple">主职：</div></el-col
              >
              <el-col :span="16"
                ><div class="grid-content bg-purple" algin="left">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="不知道，还能走多远"
                    placement="top-start"
                  >
                    <el-tag size="small">前端开发</el-tag>
                  </el-tooltip>
                </div></el-col
              >
              <el-col :span="8"
                ><div class="grid-content bg-purple">副职：</div></el-col
              >
              <el-col :span="16"
                ><div class="grid-content bg-purple">
                  <el-tooltip
                    class="item el-popper-active"
                    effect="dark"
                    content=""
                    placement="bottom-start"
                  >
                    <template v-slot:content
                      >我无法改变世界，世界也不会因我改变<br />那么我自己就创建一个世界</template
                    >
                    <el-tag size="small">不入流的网文作者</el-tag>
                  </el-tooltip>
                </div></el-col
              >
              <!-- 名言 -->
              <el-col :span="24" style="margin-top:5px;">
                <el-alert
                  style="padding: 0"
                  :closable="false"
                  title="逃避战斗是看不见明天的"
                  type="warning"
                >
                </el-alert>
              </el-col>
              <el-col :span="24">
                <div align='right'>赛罗</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-row :gutter="20" class="zwjs_box">
        <el-col :span="6"
          ><div class="grid-content bg-purple">年龄：</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple">18岁 😀</div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">爱好：</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple">美食、跑步、lol</div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">状态：</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple">
            当前对 3D世界 感兴趣
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">邮箱：</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple">
            <a href="mailto:1661936244@qq.com">1661936244@qq.com</a>
          </div></el-col
        >
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
// 名片
import { ref } from "vue";
import avatar from "@/assets/me.png";
export default {
  setup() {
    let dialogFormVisible = ref(false);
    let show = () => {
      dialogFormVisible.value = true;
    };
    return { dialogFormVisible, show, avatar };
  },
};
</script>

<style>
.zwjs_box {
  line-height: 20px;
  font-size: 13px;
}
.zwjs_box .el-col {
  margin-bottom: 6px;
}
.yf_card_box .el-card__header {
  padding: 4px 7px !important;
}
.yf_card_box .el-card__body {
  padding: 20px 4px 5px !important;
}
</style>